import {AxiosResponse} from "axios";
import {apiClient} from "@/modules/apiClient";
import {CallCalendarEvent} from "@/models/scheduling/CallCalendarEvent";
import {Call} from "@/models/scheduling/Call";
import {AddMultipleCallsCommand, CallCommand} from "@/models/scheduling/CallCommand";
import {Resource} from "@fullcalendar/resource-common";
import {UserConflictViewModel} from "@/models/UserConflictViewModel";
import {ValidateCallConflictsCommand} from "@/models/scheduling/ValidateCallConflictsCommand";

export const CallService = {
    fetchOrganizationCalls(organizationId: string, start: string, end: string): Promise<AxiosResponse<Array<CallCalendarEvent>>> {
        return apiClient.get(`/organizations/${organizationId}/calls?start=${start}&end=${end}`)
    },
    fetchProductionCalls(productionId: string, start: string, end: string): Promise<AxiosResponse<Array<CallCalendarEvent>>> {
        return apiClient.get(`/productions/${productionId}/calls?start=${start}&end=${end}`)
    },
    fetchAccountCalls(start: string, end: string): Promise<AxiosResponse<Array<CallCalendarEvent>>> {
        return apiClient.get(`/calls?start=${start}&end=${end}`)
    },
    getCall(callId: string): Promise<AxiosResponse<Call>>{
        return apiClient.get(`/calls/${callId}/details`)
    },
    getCallView(callId: string): Promise<AxiosResponse<CallCalendarEvent>>{
        return apiClient.get(`/calls/${callId}`)
    },
    getCallConflicts(callId: string): Promise<AxiosResponse<Array<UserConflictViewModel>>>{
        return apiClient.get(`/calls/${callId}/conflicts`)
    },
    validateCallConflicts(command: ValidateCallConflictsCommand): Promise<AxiosResponse<Array<UserConflictViewModel>>>{
        return apiClient.post(`/calls/validate`, command)
    },
    addCall(command: CallCommand): Promise<AxiosResponse<CallCalendarEvent>>{
        return apiClient.post(`/calls`, command)
    },
    addMultipleCalls(command: AddMultipleCallsCommand): Promise<AxiosResponse<Array<CallCalendarEvent>>>{
        return apiClient.post(`/calls/multiple`, command)
    },
    updateCall(callId: string, command: CallCommand): Promise<AxiosResponse>{
        return apiClient.put(`/calls/${callId}`, command)
    },
    removeCall(callId: string): Promise<AxiosResponse>{
        return apiClient.delete(`/calls/${callId}`)
    },
    fetchProductionResources(productionId: string): Promise<AxiosResponse<Array<Resource>>> {
        return apiClient.get(`/productions/${productionId}/resources`)
    },
    fetchProductionConflicts(productionId: string, start: string, end: string): Promise<AxiosResponse<Array<CallCalendarEvent>>> {
        return apiClient.get(`/productions/${productionId}/conflicts?start=${start}&end=${end}`)
    },
    fetchProductionAvailability(productionId: string, start: string, end: string): Promise<AxiosResponse<Array<CallCalendarEvent>>> {
        return apiClient.get(`/productions/${productionId}/availability?start=${start}&end=${end}`)
    },
}
