import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "mb-6 mb-xl-9" }
const _hoisted_2 = { class: "card-body ps-0 pe-0 pb-0" }
const _hoisted_3 = { class: "d-md-flex flex-wrap flex-sm-nowrap mb-6 d-none" }
const _hoisted_4 = {
  key: 0,
  class: "d-flex flex-center flex-shrink-0 w-md-150px h-md-50px w-lg-250px h-lg-150px me-7 mb-4"
}
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "flex-grow-1" }
const _hoisted_7 = { class: "d-flex justify-content-between align-items-start flex-wrap mb-2 mt-4" }
const _hoisted_8 = { class: "d-flex flex-column" }
const _hoisted_9 = { class: "d-flex align-items-center mb-1" }
const _hoisted_10 = { class: "text-white fs-2 fw-bolder me-3" }
const _hoisted_11 = { class: "d-flex flex-wrap fw-bold mb-4 fs-5 text-gray-500" }
const _hoisted_12 = {
  key: 0,
  class: "d-none d-lg-flex flex-wrap justify-content-start"
}
const _hoisted_13 = { class: "d-flex flex-wrap" }
const _hoisted_14 = { class: "d-flex align-items-center" }
const _hoisted_15 = { class: "fs-4 fw-bolder" }
const _hoisted_16 = {
  key: 0,
  class: "fw-bold fs-6 text-gray-500"
}
const _hoisted_17 = {
  key: 1,
  class: "fw-bold fs-6 text-gray-500"
}
const _hoisted_18 = ["href"]
const _hoisted_19 = { class: "symbol-group symbol-hover mb-3" }
const _hoisted_20 = { class: "symbol symbol-35px symbol-circle" }
const _hoisted_21 = ["src"]
const _hoisted_22 = {
  key: 1,
  class: "symbol-label bg-light-primary text-inverse-primary fw-bolder"
}
const _hoisted_23 = { class: "d-flex flex-row mb-6 d-md-none" }
const _hoisted_24 = {
  key: 0,
  class: "d-flex flex-center flex-shrink-0 w-125px h-50px me-7 mb-4"
}
const _hoisted_25 = ["src"]
const _hoisted_26 = { class: "d-flex flex-column" }
const _hoisted_27 = { class: "d-flex align-items-center mb-1" }
const _hoisted_28 = { class: "text-white fs-2 fw-bolder me-3" }
const _hoisted_29 = {
  key: 0,
  class: "d-block d-md-none text-gray-800 text-hover-primary fs-2 fw-bolder me-3"
}
const _hoisted_30 = { class: "d-flex flex-wrap fw-bold mb-4 fs-5 text-gray-500" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.production.photo !== null && _ctx.production.photo !== '')
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("img", {
                class: "mw-md-150px mw-lg-250px rounded",
                src: _ctx.production.photo,
                alt: "image"
              }, null, 8, _hoisted_5)
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.production.name), 1)
              ]),
              _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.production.organizationName), 1)
            ])
          ]),
          true
            ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                _createElementVNode("div", _hoisted_13, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.production.widgets, (widget) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: widget.name,
                      class: "border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3"
                    }, [
                      _createElementVNode("div", _hoisted_14, [
                        _createElementVNode("div", _hoisted_15, _toDisplayString(widget.name), 1)
                      ]),
                      (widget.type === 1)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_16, _toDisplayString(widget.value), 1))
                        : _createCommentVNode("", true),
                      (widget.type === 2)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                            _createElementVNode("a", {
                              class: "link-primary",
                              href: widget.link,
                              target: "_blank"
                            }, _toDisplayString(widget.value), 9, _hoisted_18)
                          ]))
                        : _createCommentVNode("", true)
                    ]))
                  }), 128))
                ]),
                _createElementVNode("div", _hoisted_19, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.activePeople, (person) => {
                    return (_openBlock(), _createBlock(_component_el_tooltip, {
                      key: person.accountId,
                      content: person.fullName,
                      placement: "top"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_20, [
                          (person.avatar !== '' && person.avatar !== null)
                            ? (_openBlock(), _createElementBlock("img", {
                                key: 0,
                                alt: "Pic",
                                src: person.avatar
                              }, null, 8, _hoisted_21))
                            : (_openBlock(), _createElementBlock("span", _hoisted_22, _toDisplayString(person.fullName[0]), 1))
                        ])
                      ]),
                      _: 2
                    }, 1032, ["content"]))
                  }), 128))
                ])
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      _createElementVNode("div", _hoisted_23, [
        _createElementVNode("div", {
          class: _normalizeClass({'me-0': _ctx.production.photo !== null && _ctx.production.photo !== ''})
        }, [
          (_ctx.production.photo !== null && _ctx.production.photo !== '')
            ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
                _createElementVNode("img", {
                  class: "mw-125px rounded",
                  src: _ctx.production.photo,
                  alt: "image"
                }, null, 8, _hoisted_25)
              ]))
            : _createCommentVNode("", true)
        ], 2),
        _createElementVNode("div", _hoisted_26, [
          _createElementVNode("div", _hoisted_27, [
            _createElementVNode("div", _hoisted_28, _toDisplayString(_ctx.production.name), 1),
            (_ctx.production.photo === null && _ctx.production.photo === '')
              ? (_openBlock(), _createElementBlock("div", _hoisted_29, _toDisplayString(_ctx.production.name), 1))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_30, _toDisplayString(_ctx.production.organizationName), 1)
        ])
      ])
    ])), [
      [_directive_loading, !_ctx.fetchComplete]
    ])
  ]))
}