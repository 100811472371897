
import {CallCalendarEvent} from "@/models/scheduling/CallCalendarEvent";
import {defineComponent, PropType} from "vue";
import moment from "moment";
import {getColorName} from "@/models/scheduling/CallType";
import {bus} from "@/modules/eventBus";

export default defineComponent({
  components: {},
  props: {
    schedule: {
      type: Object as PropType<Array<CallCalendarEvent>>,
      required: true
    }
  },
  methods: {
    viewCall(call: CallCalendarEvent) {
      bus.emit('modal:calls:viewer:view', call)
    },
    getFriendlyDateTime(date: Date): string {
      return moment(date).format('MMMM Do YYYY h:mm a').toString();
    },
    getShortFriendlyDateTime(date: Date): string {
      return moment(date).format('MMM Do h:mm a').toString();
    },
    getFriendlyTime(date: Date): string {
      return moment(date).format('h:mm a').toString();
    },
    getCallColor(color: number): string {
      return getColorName(color);
    }
  },
  data() {
    return {
      currentSelectedDate: ''
    }
  },
  computed: {
    availableDates(): Array<Object> {
      //Todo: For each call in this.schedule, check if already exists; If not, add entry.
      return [
        {
          date: '12-25-2022',
          dayOfWeek: 'Mon',
          day: 12
        }
      ];
    },
    calls(): Array<CallCalendarEvent> {
      return this.schedule.slice(0, 4);
      // return this.schedule.filter(() => {
      //   return true;
      //   //return call.start.toDateString().indexOf(this.currentSelectedDate) > -1;
      // });
    }
  }
})
