
import ScheduleWidget from "@/components/Calendar/ScheduleWidget.vue";
import CommunicationModal from "@/components/modals/CommunicationModal.vue";
import {defineComponent, ref} from "vue";
import moment from "moment";
import CommunicationViewerModal from "@/components/modals/CommunicationViewerModal.vue";
import {CommunicationViewModel} from "@/models/communications/CommunicationViewModel";
import {getCommunicationType} from "@/models/enums/CommunicationType";
import {CommunicationService} from "@/services/CommunicationService";
import {CallCalendarEvent} from "@/models/scheduling/CallCalendarEvent";
import {CallService} from "@/services/CallService";
import {Organization} from "@/models/organizations/Organization";
import {ProductionService} from "@/services/ProductionService";
import {Production} from "@/models/productions/Production";
import ProductionCard from "@/components/Entity/ProductionCard.vue";
import {bus} from "@/modules/eventBus";
import {CommunicationStatus} from "@/models/enums/CommunicationStatus";
import {useUser} from "@/store/pinia/userStore";
import {useApplication} from "@/store/pinia/applicationStore";
import {useToast} from "vue-toastification";
import {ProblemDetails} from "@/models/ProblemDetails";

export default defineComponent({
  setup() {
    const communicationViewerModal = ref<InstanceType<typeof CommunicationViewerModal>>();
    const communicationModal = ref<InstanceType<typeof CommunicationModal>>();
    const viewCommunication = (_communication: CommunicationViewModel) => {
      communicationViewerModal.value?.toggleModal(_communication);
    }
    const invokeAddCommunication = () => {
      communicationModal.value?.invokeAdd();
    }
    const userStore = useUser();
    const applicationStore = useApplication()
    const toast = useToast();
    return {
      communicationViewerModal,
      viewCommunication,
      invokeAddCommunication,
      communicationModal,
      userStore,
      applicationStore,
      toast
    }
  },
  components: {ScheduleWidget, CommunicationViewerModal, CommunicationModal, ProductionCard},
  data() {
    return {
      communications: [] as Array<CommunicationViewModel>,
      calls: [] as Array<CallCalendarEvent>,
      scheduleLoading: true,
      communicationsLoading: true,
      organization: {} as Organization,
      production: {} as Production,
      isFetching: true
    }
  },
  mounted() {
    this.fetch()
    bus.on('modal:scheduling:added', this.fetch)
    bus.on('modal:scheduling:updated', this.fetch)
    bus.on('modal:communications:refresh', this.fetch)
  },
  methods: {
    fetch() {
      if (typeof this.productionId !== 'undefined') {
        const start = moment().startOf('day');
        const end = moment().add(8, "days").endOf('day');
        CommunicationService.fetchProductionCommunications(this.productionId).then(response => {
          this.communications = response.data
        }).catch(err => {
          let errorDetails = err.response.data as ProblemDetails
          this.toast.error(errorDetails.detail)
        }).finally(() => {
          this.communicationsLoading = false
        })
        CallService.fetchProductionCalls(this.productionId, start.format(), end.format()).then(response => {
          this.calls = response.data
        }).catch(err => {
          let errorDetails = err.response.data as ProblemDetails
          this.toast.error(errorDetails.detail)
        }).finally(() => {
          this.scheduleLoading = false
        })
        ProductionService.fetch(this.productionId).then(response => {
          this.production = response.data
        }).catch(err => {
          let errorDetails = err.response.data as ProblemDetails
          this.toast.error(errorDetails.detail)
        }).finally(() => {
          this.isFetching = false
        })
      }
    },
    invokeAddCall() {
      bus.emit('modal:scheduling:add')
    },
    getFriendlyDateTime(date) {
      return moment(date).format('MMMM Do YYYY h:mm a').toString();
    },
    getCommunicationType(type) {
      return getCommunicationType(type)
    },
    getShortFriendlyDateTime(date: Date): string {
      return moment(date).format('MMM Do h:mm a').toString();
    },
    getRelativeDateTime(date: Date): string {
      return moment(date).calendar();
    },
  },
  computed: {
    isOrganization(): boolean {
      return typeof this.$route.name === "string" ? this.$route.name.indexOf('organization') > -1 : false
    },
    organizationId(): string {
      return this.$route.params['organizationId'] as string
    },
    productionId(): string {
      return this.$route.params['productionId'] as string
    },
    publishedCommunications(): Array<CommunicationViewModel> {
      return this.communications.filter(x => x.status === CommunicationStatus.Published).filter(c => {
        return moment(c.lastUpdated).isAfter(moment().subtract(7, "days").endOf('day'))
      })
    },
  },
  created() {
    this.$watch(
        () => this.$route.params,
        () => {
          if (this.applicationStore.isEntityMode) {
            this.fetch()
          }
        }
    )
  },
})
