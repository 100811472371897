
import {defineComponent} from "vue";
import {Production} from "@/models/productions/Production";
import {ProductionService} from "@/services/ProductionService";
import {WebAnalytics} from "@/modules/webAnalytics";
import {WidgetViewModel} from "@/models/WidgetViewModel";
import {PeopleService} from "@/services/PeopleService";
import {Person} from "@/models/people/Person";
import {MembershipStatus} from "@/models/enums/MembershipStatus";
import {useApplication} from "@/store/pinia/applicationStore";
import {useToast} from "vue-toastification";
import {ProblemDetails} from "@/models/ProblemDetails";

export default defineComponent({
  setup() {
    const applicationStore = useApplication()
    const toast = useToast();
    return {applicationStore, toast}
  },
  mounted() {
    this.fetch()
    this.fetchPeople()
    this.applicationStore.changeEntityMode(true)
  },
  data() {
    return {
      activeTab: 'Dashboard',
      production: {} as Production,
      people: [] as Array<Person>,
      fetchComplete: false,
      productionTypeOptions: [
        {
          text: 'Other',
          id: 0
        },
        {
          text: 'Musical',
          id: 1
        }, {
          text: 'Play',
          id: 2
        }, {
          text: 'Dance',
          id: 4
        }, {
          text: 'Opera',
          id: 3
        }, {
          text: 'Concert',
          id: 5
        }
      ]
    }
  },
  methods: {
    fetch() {
      if(typeof this.productionId !== 'undefined'){
        ProductionService.fetch(this.productionId).then(response => {
          this.production = response.data
          this.identifyProduction()
        }).catch(err => {
          let errorDetails = err.response.data as ProblemDetails
          this.toast.error(errorDetails.detail)
        }).finally(() => {
          this.fetchComplete = true
        })
      }
    },
    fetchPeople() {
      if(typeof this.productionId !== 'undefined'){
        PeopleService.fetchProduction(this.productionId).then(response => {
          this.people = response.data
        }).catch(err => {
          let errorDetails = err.response.data as ProblemDetails
          this.toast.error(errorDetails.detail)
        })
      }
    },
    identifyProduction() {
      if(typeof this.productionId !== 'undefined'){
        WebAnalytics.identifyProduction({
          productionId: this.production.productionId,
          $avatar: this.production.photo,
          name: this.production.name,
          $name: this.production.name,
          StageCode: this.production.stageCode,
          type: this.productionTypeOptions[this.production.type].text,
        })
      }
    }
  },
  computed: {
    productionId(): string {
      return this.$route.params['productionId'] as string
    },
    widgets(): Array<WidgetViewModel> {
      let widgets = [] as Array<WidgetViewModel>;
      if (this.production.metadata) {
        const widgetMetadataRaw = this.production.metadata.filter(x => x.type === 1);
        if (widgetMetadataRaw.length > 0) {
          widgets = JSON.parse(widgetMetadataRaw[0].value);
        }
      }
      return widgets;
    },
    activePeople(): Array<Person> {
      return this.people.filter(p => p.status === MembershipStatus.Active).slice(0, 10)
    },
  },
  created() {
    this.$watch(
        () => this.$route.params,
        () => {
          if (this.applicationStore.isEntityMode && this.applicationStore.isProduction) {
            this.fetch()
            this.fetchPeople()
          }
        }
    )
  },
})
